import { TypePolicies, TypePolicy } from '@apollo/client';
import uniqBy from '~/utils/uniq-by';
import { providerFields } from './providerFields';

export const typePolicies: TypePolicies = {
  // @TODO: verify if this is necessary - feels like this should be deprecated
  providers: { ...providerFields },
};

export const queryFields: TypePolicy = {
  fields: {
    form: {
      keyArgs: ['$searchByName'],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      merge(existing = [], incoming, { readField }) {
        return [...existing, ...incoming].filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (t) => readField('id', t) === readField('id', value),
            ),
        );
      },
    },
    servicesForms: {
      keyArgs: ['$serviceId'],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      merge(existing = [], incoming, { readField }) {
        return [...existing, ...incoming].filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (t) => readField('id', t) === readField('id', value),
            ),
        );
      },
    },
    customers: {
      keyArgs: ['$searchValue', '$searchByName'],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      merge(existing = [], incoming, { readField }) {
        return [...existing, ...incoming].filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (t) => readField('id', t) === readField('id', value),
            ),
        );
      },
    },
    services: {
      keyArgs: [
        '$value',
        '$status',
        '$id',
        '$employeeId',
        '$searchByName',
        '$serviceId',
      ],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      merge(existing, incoming, { args: { offset = 0 } }) {
        // Slicing is necessary because the existing data is
        // immutable, and frozen in development.
        const merged = existing ? existing.slice(0) : [];

        for (let i = 0; i < incoming.length; ++i) {
          merged[offset + i] = incoming[i];
        }

        return merged;
      },
    },
    /*  services_aggregate: {
      keyArgs: ['$value', '$status'],
    }, */
    servicePictures: {
      keyArgs: ['$serviceId'],
      merge: (existing = [], incoming) => {
        return [...existing, ...incoming];
      },
    },
    payouts: {
      keyArgs: false,
      merge(existing = { data: [] }, incoming) {
        return {
          ...existing,
          ...incoming,
          data: [...existing.data, ...incoming.data],
        };
      },
    },
    providerPictures: {
      keyArgs: false,
      merge(existing = { data: [] }, incoming) {
        return {
          ...existing,
          ...incoming,
          data: uniqBy([...existing.data, ...incoming.data], 'id'),
        };
      },
    },
    locations: {
      keyArgs: [
        '$value',
        '$locationName',
        '$locationType',
        '$providerId',
        '$serviceId',
        'name',
        'type',
      ],
      merge(existing = [], incoming, { readField }) {
        return [...existing, ...incoming].filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (t) => readField('id', t) === readField('id', value),
            ),
        );
      },
    },
    providerPayments: {
      keyArgs: false,
      merge(existing = { data: [] }, incoming) {
        return {
          ...existing,
          ...incoming,
          data: [...existing.data, ...incoming.data],
        };
      },
    },
    AvailableAppointmentDateTimeSlotsDto: {
      keyArgs: false,
      merge(existing = { availableSlots: [] }, incoming) {
        return {
          ...existing,
          ...incoming,
          availableSlots: [...existing.data, ...incoming.data],
        };
      },
    },
    appointments: {
      keyArgs: ['$jobStatus', 'jobStatus', '$locationIds'],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      merge(existing = [], incoming, { readField }) {
        return [...existing, ...incoming].filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (t) => readField('id', t) === readField('id', value),
            ),
        );
      },
    },
    schedule: {
      keyArgs: ['$employeeId', 'employeeId'],
      merge(existing = [], incoming, { readField }) {
        return [...existing, ...incoming].filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (t) => readField('id', t) === readField('id', value),
            ),
        );
      },
    },
    timeouts: {
      keyArgs: ['$userId', 'userId'],
      merge(existing = [], incoming, { readField }) {
        return [...existing, ...incoming].filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (t) => readField('id', t) === readField('id', value),
            ),
        );
      },
    },
  },
};
