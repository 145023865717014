import { onError } from '@apollo/client/link/error';
import { fromPromise, toPromise } from '@apollo/client';
import { toast as sonnerToast } from 'sonner';
import { isBrowser } from '~/constants';
import { logout, renewTokens } from '~/utils/auth';
import { getTokens } from '~/utils/localStorage';
import { logger } from '~/utils/logger';
import { t } from 'i18next';

// override the default toaster with console for SSR
const messenger = (() => {
  if (isBrowser) {
    return sonnerToast;
  }
  return logger;
})();

export const onErrorLink = onError(
  ({ graphQLErrors, operation, forward, networkError }) => {
    if (networkError) {
      if (!isBrowser) {
        logger.error(JSON.stringify(networkError));
      }
      messenger.error('CONNECTION FAILED: Are you connected to the internet?');
      return;
    }

    graphQLErrors?.forEach((error) => {
      const originalError = error?.extensions.originalError as {
        statusCode: number;
        message: string;
      };
      const hasuraErrorCode = error?.extensions.code;

      if (
        originalError?.statusCode === 401 ||
        hasuraErrorCode === 'invalid-jwt'
      ) {
        return fromPromise(
          (async () => {
            try {
              await renewTokens();
            } catch (err) {
              logger.warn(`Logging out due to: ${originalError?.message}`);
              await logout();
              throw new Error('Unable to re-authorize');
            }

            const { accessToken } = getTokens();

            operation.setContext({
              headers: {
                ...operation.getContext().headers,
                ...(accessToken
                  ? { Authorization: `Bearer ${accessToken}` }
                  : {}),
              },
            });

            return toPromise(forward(operation));
          })(),
        );
      }

      if (error?.extensions?.code === 'INTERNAL_SERVER_ERROR') {
        if (!isBrowser) {
          logger.error(JSON.stringify(error));
        }
        messenger.error(t('toasts.internalError'));
        return;
      } else if (error?.extensions?.code === 'FORBIDDEN') {
        if (!isBrowser) {
          logger.error(JSON.stringify(error));
        }
        messenger.error(error?.message);
        return;
      }
    });
  },
);
