import { createHttpLink } from '@apollo/client';
import { HASURA_GRAPHQL_URI } from '~/constants';

const RelevantHeaderRegExp = /^(x-hasura-.+|authorization)/i;
export function extractRequestRelevantHeaders(headers: Request['headers']) {
  return (
    Object.fromEntries(
      [...(headers?.entries?.() || [])].filter(([name]) =>
        name.match(RelevantHeaderRegExp),
      ),
    ) || {}
  );
}

export const httpLink = (request?: Request) =>
  createHttpLink({
    uri: HASURA_GRAPHQL_URI,
    ...(request?.headers && {
      headers: {
        ...extractRequestRelevantHeaders(request.headers),
      },
    }),
    //credentials: request?.credentials ?? 'omit', // or "same-origin" if your backend server is the same domain
  });
