import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { HASURA_GRAPHQL_WS_URI, isBrowser } from '~/constants';
import { getTokens, getUser } from '~/utils/localStorage';

export const getWsLink = () => {
  if (!isBrowser) return null;

  const link = new GraphQLWsLink(
    createClient({
      url: HASURA_GRAPHQL_WS_URI,
      lazy: true,
      connectionParams: () => {
        const { accessToken } = getTokens();
        const { role } = getUser();
        return {
          ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
          ...(role && { 'x-hasura-role': role }),
          headers: {
            ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
            ...(role && { 'x-hasura-role': role }),
          },
        };
      },
    }),
  );
  return link;
};
