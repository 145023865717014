import { InMemoryCacheConfig, defaultDataIdFromObject } from '@apollo/client';
import { queryFields, typePolicies } from './fieldsAndPolicies';

export const cacheConfig: InMemoryCacheConfig = {
  dataIdFromObject(responseObject) {
    const customPrefixes = ['nest_'];

    if (!responseObject?.__typename || !responseObject?.id) {
      return defaultDataIdFromObject(responseObject);
    }

    let __typename = responseObject.__typename;

    // Remove custom prefix (merge custom types from nestjs)
    customPrefixes.every((prefix) => {
      if (__typename.startsWith(prefix)) {
        __typename = __typename.slice(prefix.length);
        //break condition
        return false;
      }
      // continue loop
      return true;
    });

    switch (__typename) {
      //Add cases

      // default case
      default:
        return `${__typename}:${responseObject.id}`;
    }
  },
  typePolicies: {
    Query: {
      ...queryFields,
    },
    ...typePolicies,
  },
};
