import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  from,
} from '@apollo/client';
import { httpLink } from './links/httpLink';
import { onErrorLink } from './links/errorLink';
import { authLink } from './links/authLink';
import { cacheConfig } from './cacheConfig';
import { isBrowser } from '~/constants';
import { splitLink } from './links/splitLink';

const clientSideApolloClient = (
  isBrowser
    ? new ApolloClient({
        cache: new InMemoryCache(cacheConfig),
        ssrMode: false,
        // todo: this runs server side which means we still don't have access to window, how to fix it?
        // .restore(window ? window.__APOLLO_STATE__ : {}),
        link: from([onErrorLink, authLink, splitLink]),
      })
    : null
) as ApolloClient<NormalizedCacheObject>;

export function getApolloClient(request?: Request | undefined) {
  return isBrowser
    ? clientSideApolloClient
    : new ApolloClient({
        ssrMode: true,
        cache: new InMemoryCache(),
        link: from([onErrorLink, httpLink(request)]),
      });
}
