import { ApolloLink } from '@apollo/client';
import { getTokens, getUser } from '~/utils/localStorage';

export const authLink = new ApolloLink((operation, forward) => {
  const { accessToken } = getTokens();
  const { role } = getUser();
  operation.setContext({
    headers: {
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      ...(role && { 'x-hasura-role': role }),
      ...operation.getContext().headers,
    },
  });
  return forward(operation);
});
