import {
  // LogoutDocument,
  // LogoutMutation,
  RenewTokensDocument,
  RenewTokensMutation,
  RenewTokensMutationVariables,
} from '@hasura/__generated__';
import { getApolloClient } from '../apolloClient';
import { clearSession, getTokens, setTokens } from './localStorage';
import { isBrowser } from '~/constants';

export const renewTokens = async () => {
  if (isBrowser) {
    localStorage.removeItem('accessToken');
  }
  const { refreshToken } = getTokens();
  const apolloClient = getApolloClient(undefined);
  const result = await apolloClient.mutate<
    RenewTokensMutation,
    RenewTokensMutationVariables
  >({
    mutation: RenewTokensDocument,
    variables: { refreshToken: refreshToken || '' },
  });
  if (!result.data) return;

  setTokens({
    accessToken: result.data.refreshTokens.accessToken,
    refreshToken: result.data.refreshTokens.refreshToken,
  });
};

export const logout = async () => {
  // await getApolloClient().mutate<LogoutMutation>({
  //   mutation: LogoutDocument,
  // });
  clearSession();
};
