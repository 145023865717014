import { split } from '@apollo/client';
import { getWsLink } from './wsLink';
import { getMainDefinition } from '@apollo/client/utilities';
import { httpLink } from './httpLink';

const wsLink = getWsLink();

export const splitLink = wsLink
  ? split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink(),
    )
  : httpLink();
