import { TypePolicy } from '@apollo/client';

export const providerFields: TypePolicy['fields'] = {
  locations: {
    keyArgs: ['$value'],
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    merge(existing, incoming, { args: { offset = 0 } }) {
      // Slicing is necessary because the existing data is
      // immutable, and frozen in development.
      const merged = existing ? existing.slice(0) : [];

      for (let i = 0; i < incoming.length; ++i) {
        merged[offset + i] = incoming[i];
      }

      return merged;
    },
  },
  providerPictures: {
    keyArgs: false,
    merge: (existing = [], incoming) => {
      return [...existing, ...incoming];
    },
  },
  customers: {
    keyArgs: false,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    merge(existing, incoming, { args: { offset = 0 } }) {
      // Slicing is necessary because the existing data is
      // immutable, and frozen in development.
      const merged = existing ? existing.slice(0) : [];

      for (let i = 0; i < incoming.length; ++i) {
        merged[offset + i] = incoming[i];
      }

      return merged;
    },
  },
};
